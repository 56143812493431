import { FuseNavigationItem } from '@fuse/components/navigation';

export const coreNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:rectangle-group',
        link: '/dashboard',
        // translation: 'NAVIGATION.DASHBOARD'
    },
    {
        id: 'trades',
        title: 'Trades',
        type: 'basic',
        icon: 'heroicons_outline:square-3-stack-3d',
        link: '/trades',
        // translation: 'NAVIGATION.BOOKINGS'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie', //chart-square-bar
        link: '/reports',
        // translation: 'NAVIGATION.BOOKINGS'
    },
    {
        id: 'portfolios',
        title: 'Portfolios',
        type: 'basic',
        icon: 'heroicons_outline:wallet', //heroicons_outline:scale
        link: '/portfolios',
        // translation: 'NAVIGATION.BOOKINGS'
    },
    {
        id: 'trading-pairs',
        title: 'Trading pairs',
        type: 'basic',
        icon: 'heroicons_outline:banknotes', //heroicons_outline:scale
        link: '/trading-pairs',
        // translation: 'NAVIGATION.BOOKINGS'
    },
    // {
    //     id: 'settings',
    //     title: 'Settings',
    //     type: 'collapsable',
    //     icon: 'heroicons_outline:cog',
    //     // translation: 'NAVIGATION.BOOKINGS',
    //     children: [
    //         {
    //             id: 'portfolios',
    //             title: 'Portfolios',
    //             type: 'basic',
    //             icon: 'heroicons_outline:collection',
    //             link: '/settings/portfolios',
    //             // translation: 'NAVIGATION.RESOURCE_TYPES'
    //         },
    //         {
    //             id: 'portfolio-memberships',
    //             title: 'Portfolio memberships',
    //             type: 'basic',
    //             icon: 'heroicons_outline:user-group',
    //             link: '/settings/portfolio-memberships',
    //             // translation: 'NAVIGATION.CUSTOMERS'
    //         }, {
    //             id: 'portfolio-transactions',
    //             title: 'Portfolio transactions',
    //             type: 'basic',
    //             icon: 'heroicons_outline:cash',
    //             link: '/settings/portfolio-transactions',
    //             // translation: 'NAVIGATION.CUSTOMERS'
    //         },
    //         {
    //             id: 'trading-patterns',
    //             title: 'Trading patterns',
    //             type: 'basic',
    //             icon: 'heroicons_outline:qrcode',
    //             link: '/settings/trading-patterns',
    //             // translation: 'NAVIGATION.BOOKINGS'
    //         },
    //         {
    //             id: 'trading-patterns',
    //             title: 'Trading checklist',
    //             type: 'basic',
    //             icon: 'heroicons_outline:clipboard-check',
    //             link: '/settings/trading-patterns',
    //             // translation: 'NAVIGATION.BOOKINGS'
    //         }
    //     ]
    // }
];

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'apps',
        title: 'Trading metrics',
        subtitle: 'Description, helps with trading',
        type: 'group',
        children: [
            ...coreNavigation,
            // {
            //     id: 'access-settings',
            //     title: 'Settings',
            //     type: 'basic',
            //     icon: 'heroicons_outline:shield-check',
            //     link: '/inventory/activity-types',
            //     // translation: 'NAVIGATION.ACCESS_SETTINGS'
            // }
        ],
    },
    {
        id: 'documentation',
        title: 'Documentation',
        subtitle: 'Everything you need to know about Fuse',
        type: 'group',
        icon: 'heroicons_outline:information-circle',
        children: [
            {
                id: 'documentation.changelog',
                title: 'Changelog',
                type: 'basic',
                icon: 'heroicons_outline:megaphone',
                link: '/docs/changelog',
                badge: {
                    title: '13.6.2',
                    classes: 'px-2 bg-yellow-300 text-black rounded-full',
                },
            },
            {
                id: 'documentation.guides',
                title: 'Guides',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/docs/guides',
            },
        ],
    },
    {
        id: 'apps.help-center',
        title: 'Help Center',
        type: 'collapsable',
        icon: 'heroicons_outline:information-circle',
        link: '/apps/help-center',
        children: [
            {
                id: 'apps.help-center.home',
                title: 'Home',
                type: 'basic',
                link: '/apps/help-center',
                exactMatch: true,
            },
            {
                id: 'apps.help-center.faqs',
                title: 'FAQs',
                type: 'basic',
                link: '/apps/help-center/faqs',
            },
            {
                id: 'apps.help-center.guides',
                title: 'Guides',
                type: 'basic',
                link: '/apps/help-center/guides',
            },
            {
                id: 'apps.help-center.support',
                title: 'Support',
                type: 'basic',
                link: '/apps/help-center/support',
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    // {
    //     id: 'dashboards',
    //     title: 'Dashboards',
    //     tooltip: 'Dashboards',
    //     type: 'aside',
    //     icon: 'heroicons_outline:home',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'apps',
    //     title: 'Apps',
    //     tooltip: 'Apps',
    //     type: 'aside',
    //     icon: 'heroicons_outline:qrcode',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'pages',
    //     title: 'Pages',
    //     tooltip: 'Pages',
    //     type: 'aside',
    //     icon: 'heroicons_outline:document-duplicate',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'user-interface',
    //     title: 'UI',
    //     tooltip: 'UI',
    //     type: 'aside',
    //     icon: 'heroicons_outline:collection',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'navigation-features',
    //     title: 'Navigation',
    //     tooltip: 'Navigation',
    //     type: 'aside',
    //     icon: 'heroicons_outline:bars-3',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    ...coreNavigation,

    // {
    //     id: 'dashboards',
    //     title: 'Dashboards',
    //     type: 'group',
    //     icon: 'heroicons_outline:home',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'apps',
    //     title: 'Apps',
    //     type: 'group',
    //     icon: 'heroicons_outline:qrcode',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'pages',
    //     title: 'Pages',
    //     type: 'group',
    //     icon: 'heroicons_outline:document-duplicate',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'user-interface',
    //     title: 'UI',
    //     type: 'group',
    //     icon: 'heroicons_outline:collection',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // },
    // {
    //     id: 'navigation-features',
    //     title: 'Misc',
    //     type: 'group',
    //     icon: 'heroicons_outline:bars-3',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // }
];
