<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        @if (showAvatar && userService.item$().avatar) {
            <img class="h-7 w-7 rounded-full" [src]="userService.item$().avatar" />
        }
        @if (!showAvatar || !userService.item$().avatar) {
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
        <span
            class="absolute bottom-0 right-0 h-2 w-2 rounded-full"
            [ngClass]="{
                'mb-px mr-px': !showAvatar || !userService.item$().avatar,
                'bg-green-500': userService.item$().status === 'online',
                'bg-amber-500': userService.item$().status === 'away',
                'bg-red-500': userService.item$().status === 'busy',
                'bg-gray-400': userService.item$().status === 'not-visible',
            }"
        ></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <!-- <button mat-menu-item> -->
    <span class="ml-5 mr-5 mt-2 flex flex-col leading-none">
        <span>Signed in as</span>
        <span class="mt-1.5 text-md font-medium">{{ userService.item$().email }}</span>
    </span>
    <!-- </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="goToSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Account</span>
    </button>
    <button mat-menu-item (click)="settingsDrawer.toggle()" #notificationsOrigin>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Display settings</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<fuse-drawer
    class="z-999 w-screen min-w-screen sm:w-100 sm:min-w-100"
    fixed
    [mode]="'over'"
    [name]="'settingsDrawer'"
    [position]="'right'"
    #settingsDrawer
>
    <div class="bg-card flex w-full flex-col overflow-auto">
        <div class="flex h-20 min-h-20 flex-row items-center bg-primary px-6 text-white">
            <mat-icon class="text-current icon-size-7" [svgIcon]="'heroicons_solid:cog'"></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div>
            <button class="ml-auto" mat-icon-button (click)="settingsDrawer.close()">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col p-6">
            <!-- Theme -->
            <div class="text-secondary text-md font-semibold">THEME</div>
            <div class="mt-6 grid grid-cols-2 gap-3 sm:grid-cols-3">
                @for (theme of configService.config().themes; track theme) {
                    <div
                        class="bg-hover flex cursor-pointer items-center justify-center rounded-full px-4 py-3 ring-inset ring-primary"
                        [class.ring-2]="configService.config().theme === theme.id"
                        [ngClass]="theme.id"
                        (click)="setTheme(theme.id)"
                    >
                        <div class="h-3 w-3 flex-0 rounded-full bg-primary"></div>
                        <div
                            class="ml-2.5 truncate font-medium leading-5"
                            [class.text-secondary]="configService.config().theme !== theme.id"
                        >
                            {{ theme.name }}
                        </div>
                    </div>
                }
            </div>

            <hr class="my-8" />

            <!-- Scheme -->
            <div class="text-secondary text-md font-semibold">SCHEME</div>
            <div class="mt-6 grid grid-cols-3 justify-items-start gap-3">
                <!-- Auto -->
                <div
                    class="bg-hover flex cursor-pointer items-center rounded-full py-3 pl-5 pr-6 ring-inset ring-primary"
                    [class.ring-2]="configService.config().scheme === 'auto'"
                    matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query."
                    (click)="setScheme('auto')"
                >
                    <div class="flex items-center overflow-hidden rounded-full">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:bolt'"></mat-icon>
                    </div>
                    <div
                        class="ml-2 flex items-center font-medium leading-5"
                        [class.text-secondary]="configService.config().scheme !== 'auto'"
                    >
                        Auto
                    </div>
                </div>
                <!-- Dark -->
                <div
                    class="bg-hover flex cursor-pointer items-center rounded-full py-3 pl-5 pr-6 ring-inset ring-primary"
                    [class.ring-2]="configService.config().scheme === 'dark'"
                    (click)="setScheme('dark')"
                >
                    <div class="flex items-center overflow-hidden rounded-full">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
                    </div>
                    <div
                        class="ml-2 flex items-center font-medium leading-5"
                        [class.text-secondary]="configService.config().scheme !== 'dark'"
                    >
                        Dark
                    </div>
                </div>
                <!-- Light -->
                <div
                    class="bg-hover flex cursor-pointer items-center rounded-full py-3 pl-5 pr-6 ring-inset ring-primary"
                    [class.ring-2]="configService.config().scheme === 'light'"
                    (click)="setScheme('light')"
                >
                    <div class="flex items-center overflow-hidden rounded-full">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
                    </div>
                    <div
                        class="ml-2 flex items-center font-medium leading-5"
                        [class.text-secondary]="configService.config().scheme !== 'light'"
                    >
                        Light
                    </div>
                </div>
            </div>

            <hr class="my-8" />

            <!-- Layout -->
            <div class="text-secondary text-md font-semibold">LAYOUT</div>
            <div class="mt-6 grid grid-cols-3 gap-3">
                <!-- Empty -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('empty')">
                    <div
                        class="flex h-20 flex-col overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'empty'"
                    >
                        <div class="flex flex-auto flex-col bg-gray-50 dark:bg-gray-900"></div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'empty'"
                    >
                        Empty
                    </div>
                </div>

                <!-- Classic -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('classic')">
                    <div
                        class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'classic'"
                    >
                        <div class="w-8 bg-gray-100 dark:bg-gray-800">
                            <div class="mx-1.5 mt-3 space-y-1">
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-auto flex-col border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="mr-1.5 flex h-full items-center justify-end">
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'classic'"
                    >
                        Classic
                    </div>
                </div>

                <!-- Classy -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('classy')">
                    <div
                        class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'classy'"
                    >
                        <div class="w-8 bg-gray-100 dark:bg-gray-800">
                            <div class="mx-1 mt-1 flex items-center">
                                <div
                                    class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="ml-auto h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="ml-0.5 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                            </div>
                            <div
                                class="mx-auto mt-2.5 h-4 w-4 rounded-full bg-gray-300 dark:bg-gray-700"
                            ></div>
                            <div class="mx-1 mt-2 space-y-1">
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-auto flex-col border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="mr-2 flex h-full items-center justify-end">
                                    <div
                                        class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'classy'"
                    >
                        Classy
                    </div>
                </div>

                <!-- Compact -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('compact')">
                    <div
                        class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'compact'"
                    >
                        <div class="w-5 bg-gray-100 dark:bg-gray-800">
                            <div
                                class="mx-auto mt-2 h-3 w-3 rounded-sm bg-gray-300 dark:bg-gray-700"
                            ></div>
                            <div class="mt-2 flex w-full flex-col items-center space-y-1">
                                <div
                                    class="h-2.5 w-3 rounded-sm bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-2.5 w-3 rounded-sm bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-2.5 w-3 rounded-sm bg-gray-300 dark:bg-gray-700"
                                ></div>
                            </div>
                        </div>
                        <div class="flex flex-auto flex-col border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="mr-1.5 flex h-full items-center justify-end">
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'compact'"
                    >
                        Compact
                    </div>
                </div>

                <!-- Dense -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('dense')">
                    <div
                        class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'dense'"
                    >
                        <div class="w-4 bg-gray-100 dark:bg-gray-800">
                            <div
                                class="mx-auto mt-2 h-2 w-2 rounded-sm bg-gray-300 dark:bg-gray-700"
                            ></div>
                            <div class="mt-2 flex w-full flex-col items-center space-y-1">
                                <div class="h-2 w-2 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-2 w-2 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-2 w-2 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-auto flex-col border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="mr-1.5 flex h-full items-center justify-end">
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'dense'"
                    >
                        Dense
                    </div>
                </div>

                <!-- Thin -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('thin')">
                    <div
                        class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'thin'"
                    >
                        <div class="w-3 bg-gray-100 dark:bg-gray-800">
                            <div
                                class="mx-auto mt-2 h-1.5 w-1.5 rounded-sm bg-gray-300 dark:bg-gray-700"
                            ></div>
                            <div class="mt-2 flex w-full flex-col items-center space-y-1">
                                <div
                                    class="h-1.5 w-1.5 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1.5 w-1.5 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1.5 w-1.5 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1.5 w-1.5 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1.5 w-1.5 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                            </div>
                        </div>
                        <div class="flex flex-auto flex-col border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="mr-1.5 flex h-full items-center justify-end">
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'thin'"
                    >
                        Thin
                    </div>
                </div>

                <div class="col-span-2"></div>

                <!-- Centered -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('centered')">
                    <div
                        class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'centered'"
                    >
                        <div
                            class="mx-2 my-1 flex flex-auto flex-col overflow-hidden rounded-md border"
                        >
                            <div class="flex h-3 items-center bg-gray-100 dark:bg-gray-800">
                                <div class="ml-1.5 flex">
                                    <div
                                        class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                </div>
                                <div class="ml-auto mr-1.5 flex items-center justify-end">
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                    <div
                                        class="ml-1 h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                    ></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'centered'"
                    >
                        Centered
                    </div>
                </div>

                <!-- Enterprise -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('enterprise')">
                    <div
                        class="flex h-20 flex-col overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'enterprise'"
                    >
                        <div class="flex h-3 items-center bg-gray-100 px-2 dark:bg-gray-800">
                            <div class="h-2 w-2 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="ml-auto flex items-center justify-end space-x-1">
                                <div
                                    class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                            </div>
                        </div>
                        <div
                            class="flex h-3 items-center space-x-1 border-b border-t bg-gray-100 px-2 dark:bg-gray-800"
                        >
                            <div class="h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                        </div>
                        <div
                            class="mx-2 my-1 flex flex-auto flex-col overflow-hidden rounded border"
                        >
                            <div class="flex flex-auto bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'enterprise'"
                    >
                        Enterprise
                    </div>
                </div>

                <!-- Modern -->
                <div class="flex cursor-pointer flex-col" (click)="setLayout('modern')">
                    <div
                        class="flex h-20 flex-col overflow-hidden rounded-md border-2 hover:opacity-80"
                        [class.border-primary]="configService.config().layout === 'modern'"
                    >
                        <div
                            class="flex h-4 items-center border-b bg-gray-100 px-2 dark:bg-gray-800"
                        >
                            <div class="h-2 w-2 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="ml-2 flex h-3 items-center space-x-1">
                                <div
                                    class="h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1 w-3 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                            </div>
                            <div class="ml-auto flex items-center justify-end space-x-1">
                                <div
                                    class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                                <div
                                    class="h-1 w-1 rounded-full bg-gray-300 dark:bg-gray-700"
                                ></div>
                            </div>
                        </div>
                        <div class="flex flex-auto flex-col">
                            <div class="flex flex-auto bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="text-secondary mt-2 text-center text-md font-medium"
                        [class.text-primary]="configService.config().layout === 'modern'"
                    >
                        Modern
                    </div>
                </div>
            </div>
        </div>
    </div>
</fuse-drawer>
