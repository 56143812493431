import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { ReportPattern } from 'app/api/models/report-pattern';

@Injectable({
    providedIn: 'root',
})
export class ReportPatternMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        //fill data
        const reportPatternData: ReportPattern[] = [];
        const min = 0,
            max = 400;
        for (let i = 0; i < 200; i++) {
            //temp data

            const _mistakeCount = Math.floor(Math.random() * (500 - min + 1)) + min;
            const _setupCount = Math.floor(Math.random() * (500 - min + 1)) + min;
            const _riskCount = Math.floor(Math.random() * (500 - min + 1)) + min;
            const _winCount = Math.floor(Math.random() * (500 - min + 1)) + min;
            const _lossCount = Math.floor(Math.random() * (500 - min + 1)) + min;
            const _tradeCount = _setupCount + _mistakeCount + _winCount + _lossCount;

            reportPatternData.push({
                id: i + '',

                tradingPattern: {
                    id: '1',
                    name: `RSI ${i}`,
                    description: 'RSI description',
                    //
                    portfolio: {
                        id: '1',
                        name: 'name',
                    },
                    timeFramesEnabled: true,
                    longSetup: true,
                    longRisk: false,
                    shortSetup: false,
                    shortRisk: false,
                },
                tradingPatternName: `RSI ${i}`,

                profitFactor: 1,

                returnAmount:
                    Math.floor(Math.random() * 2000) * [-1, 1][Math.floor(Math.random() * 2)],
                returnWin: Math.floor(Math.random() * 2000),
                returnLoss:
                    Math.floor(Math.random() * 2000) * [-1, 1][Math.floor(Math.random() * 2)],
                returnLong:
                    Math.floor(Math.random() * 2000) * [-1, 1][Math.floor(Math.random() * 2)],
                returnShort:
                    Math.floor(Math.random() * 2000) * [-1, 1][Math.floor(Math.random() * 2)],

                returnWinPercent: Math.floor(Math.random() * (100 - 0 + 1)) + min,

                tradeCount: _tradeCount,
                mistakeCount: _mistakeCount,
                setupCount: _setupCount,
                riskCount: _riskCount,
                winCount: _winCount,
                lossCount: _lossCount,

                averageReturn:
                    Math.floor(Math.random() * 20000) * [-1, 1][Math.floor(Math.random() * 2)],
                averageProfit:
                    Math.floor(Math.random() * 2000) * [-1, 1][Math.floor(Math.random() * 2)],

                averageLoser:
                    Math.floor(Math.random() * 2000) * [-1, 1][Math.floor(Math.random() * 2)],

                fees: Math.floor(Math.random() * (max - min + 1)) + min,
            });
        }
        // Register Mock API handlers
        super(reportPatternData, 'v1/report-pattern', 'v1/report-pattern/{id}');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // getFilterModel(key: string, value: string): FilterModel
    // {
    //     const tempFilter: FilterModel = {
    //         filterItemType: FilterItemType.TEXT,
    //         filterSearchType: FilterSearchType.EQUALS,
    //         name: 'reportType',
    //         value: ReportOverviewType.RETURN_CURRENCY
    //     };

    //     switch (key)
    //     {
    //         case 'reportType': {
    //             tempFilter.name = 'reportType';
    //             // Coerce the value to a boolean
    //             tempFilter.value = value as ReportOverviewType;;
    //             break;
    //         }
    //     }
    //     return tempFilter;
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    private _randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
