<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false"
    >
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex h-16 items-center px-6">
                <img src="assets/logo/logo-dark.png" alt="Trading Metrics" />
            </div>
            <!-- Separator -->
            <div class="ml-3 mr-3 flex items-center">
                <div class="mt-px flex-auto border-t"></div>
                <!-- <div class="text-secondary mx-2">Or continue with</div> -->
                <div class="mt-px flex-auto border-t"></div>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
}

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center bg-gray-900 px-1 shadow dark:border-b dark:bg-gray-900 dark:bg-transparent dark:shadow-none sm:h-14 md:px-4 print:hidden"
    >
        @if (!isScreenSmall) {
            <!-- Logo -->
            <div class="mx-2 flex items-center lg:mr-2">
                <div class="hidden lg:flex">
                    <!-- <img
            class="dark:hidden w-24"
            src="assets/images/logo/logo-text.svg"> -->
                    <img class="mb-1 h-8" src="assets/logo/logo-dark.png" />
                </div>
                <img class="mb-1 flex h-8 lg:hidden" src="assets/logo/logo-dark.png" />
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation
                class="dark mr-2 bg-gray-900 print:hidden"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"
            ></fuse-horizontal-navigation>
        }
        <!-- Logo -->
        @if (isScreenSmall) {
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            @if (!isScreenExtraSmall) {
                <!-- Logo -->
                <div class="mx-2 mr-2 flex items-center">
                    <div class="flex">
                        <img class="mb-1 h-8" src="assets/logo/logo-dark.png" />
                    </div>
                </div>
            }
        }
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <languages></languages>
            <!-- <messages></messages> -->
            <notifications></notifications>
            <user></user>
            <!-- <settings></settings> -->
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
        </div>
        <!-- Navigation toggle button -->
        <!-- <ng-container *ngIf="isScreenSmall">
            <button
              mat-icon-button
              (click)="toggleNavigation('mainNavigation')"
              >
              <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
          </ng-container> -->
    </div>

    <!-- Content -->
    <div class="flex w-full flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
          Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-1 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">TradeActa &copy; {{currentYear}}</span>
      </div> -->
</div>
